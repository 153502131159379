import GATSBY_COMPILED_MDX from "/Users/michaeldawson/Code/michael-dawson-website/src/markdown-pages/impulse.mdx";
import {MDXProvider} from "@mdx-js/react";
import React from "react";
import Main from "./Main";
import MyCountup from "./MyCountup";
import RecommendationButton from "./RecommendationButton";
import Redirect from "./Redirect";
import Slider from "./Slider";
const shortcodes = {
  Slider,
  MyCountup,
  RecommendationButton,
  Redirect
};
function PageTemplate(_ref) {
  let {data, children} = _ref;
  return React.createElement(React.Fragment, null, React.createElement(MDXProvider, {
    components: shortcodes
  }, React.createElement(Main, {
    data: data
  }, children)));
}
export default function GatsbyMDXWrapper(props) {
  return React.createElement(PageTemplate, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
const query = "1901369442";
